<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <div class="app flex-row align-items-center">
        <div class="container">
          <b-row class="justify-content-center">
            <b-col
              v-if="!alreadyActive"
              class="text-center"
            >
              <h1 class="text-center">
                Ваша электронная почта успешно верифицированна.
              </h1>
              <p class="text-center">
                Спасибо, и добро пожаловать на борт. Совсем скоро Вы сможете пользоваться нашим сервисом.
              </p>
              <router-link
                to="/login"
                tag="button"
                class="btn active mt-3 mx-auto btn-primary"
                :disabled="!success || loading"
                :class="{'loading' : loading}"
              >
                Продолжить верификацию
              </router-link>
            </b-col>
            <b-col
              v-if="alreadyActive"
              class="text-center"
            >
              <h1 class="text-center">
                Ваш пользователь уже активирован
              </h1>
              <p class="text-center">
                Для авторизации в системе перейдите на страницу входа и введите свои логин и пароль.
              </p>
              <router-link
                to="/login"
                tag="button"
                class="btn active mt-3 mx-auto btn-primary"
                :disabled="loading"
                :class="{'loading' : loading}"
              >
                Перейти на страницу входа
              </router-link>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {activationEmail} from '../services/api';

export default {
  name: 'Confirm',
  data() {
    return {
      token: {
        activation: this.$route.query.activation,
      },
      loading: true,
      success: false,
      alreadyActive: false,
    };
  },
  mounted() {
    this.activationEmail();
  },
  methods: {
    async activationEmail() {
      this.loading = true;
      const response = await activationEmail(this.token);
      if (response && response.status === 200) {
        this.success = true;
      } else if (response && response.data && response.data.message === 'Пользователь уже активирован.' ) {
        console.log(response);
        this.alreadyActive = true;
      }
      console.log(response);
      this.loading = false;
    },
  },
};
</script>

